import React, { useState, useRef, useEffect } from "react";
import styles from "./LanguageSelector.module.css";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faChevronDown,
  faChevronUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

const languages = [
  { code: "en", name: "English", countryCode: "GB" },
  { code: "da", name: "Dansk", countryCode: "DK" },
];

function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const selectorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (code) => {
    i18n.changeLanguage(code);
    setIsOpen(false);

    // Update the URL with the new language code
    const newPathname = location.pathname.replace(/^\/[a-z]{2}/, `/${code}`);
    navigate(newPathname + location.search + location.hash);
  };

  // Extract base language code (e.g., 'en' from 'en-US')
  const selectedLanguage = i18n.language.split("-")[0];

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.languageSelector} ref={selectorRef}>
      <button
        className={styles.selectorButton}
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <FontAwesomeIcon icon={faGlobe} className={styles.globeIcon} />
        <span className={styles.languageName}>
          {languages.find((lang) => lang.code === selectedLanguage)?.name ||
            "Language"}
        </span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className={styles.chevronIcon}
        />
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu} role="listbox">
          {languages.map((lang) => (
            <li key={lang.code} className={styles.dropdownItem}>
              <button
                onClick={() => handleLanguageChange(lang.code)}
                className={styles.languageButton}
                role="option"
                aria-selected={lang.code === selectedLanguage}
              >
                <ReactCountryFlag
                  countryCode={lang.countryCode}
                  svg
                  className={styles.flagIcon}
                  title={lang.code}
                />
                <span className={styles.languageName}>{lang.name}</span>
                {lang.code === selectedLanguage && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles.checkIcon}
                  />
                )}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LanguageSelector;
