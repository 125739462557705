import React from "react";
import styles from "./HomepageContact.module.css";
import Section from "../../molecules/Section";
import Contact from "../../molecules/Contact";

function HomepageContact() {
  return (
    <Section className={styles.contactsection}>
      <Contact email="info@heimhub.dk" phonenumber="+ 45 7370 5949" />
    </Section>
  );
}

export default HomepageContact;
