import React from "react";
import styles from "./SlideContent.module.css";

const SlideContent = ({ title, description, image }) => {
  return (
    <div className={styles.slideContent}>
      <h2 className={styles.slideTitle}>{title}</h2>
      <p className={styles.slideDescription}>{description}</p>
    </div>
  );
};

export default SlideContent;
