import React from "react";
import styles from "./StepsList.module.css";
import { motion } from "framer-motion";
import { HeadingMedium, Link } from "../../atoms";
import { useTranslation } from "react-i18next";

const StepsList = ({ steps }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.stepsList}>
      {steps.map((step, index) => (
        <div
          key={step.number}
          className={`${styles.stepContainer} ${
            index % 2 === 0 ? styles.left : styles.right
          }`}
        >
          <div className={styles.dotContainer}>
            <motion.div
              className={styles.dot}
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>

          <motion.div
            className={styles.step}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className={styles.steplabel}>{step.label}</div>
            <HeadingMedium>{step.titel}</HeadingMedium>
            <div className={styles.stepContent}>{step.content}</div>
            <div className={styles.readmore}>
              <Link
                to={"tenant#sectionprocess"}
                label={t("read_more")}
                className={styles.readmorebuttonCustom}
              >
                {step.link}
              </Link>
            </div>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default StepsList;
