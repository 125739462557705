import React from "react";
import { SectionLandlordIntro } from "../../templates";
import { SectionLandlordInstantValue } from "../../templates";
import { SectionLandlordMoreSatisfied } from "../../templates";
import { SectionLandlordEffective } from "../../templates";
import { SectionHomepageContact } from "../../templates";
import { SectionLandlordData } from "../../templates";

function Landlord() {
  return (
    <>
      <SectionLandlordIntro />
      <SectionLandlordInstantValue />
      <SectionLandlordMoreSatisfied />
      <SectionLandlordEffective />
      <SectionLandlordData />
      <SectionHomepageContact />
    </>
  );
}

export default Landlord;
