import React from "react";
import Text from "../../atoms/Text";
import Image from "../../atoms/Image";
import styles from "./Address.module.css";

const Address = () => {
  return (
    <div>
      <Image src="/Images/Heimhub-logo-white.png" className={styles.logo} />
      <Text>HeimHub Aps</Text>
      <Text>CVR NR: 430831112</Text>
      <Text>Østerled 28b</Text>
      <Text>4300 Holbæk</Text>
      <Text>Denmark</Text>
    </div>
  );
};

export default Address;
