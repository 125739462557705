import React from "react";
import styles from "./LandlordIntro.module.css";
import { useTranslation } from "react-i18next";
import Overlay from "../../molecules/Overlay";
import { Image } from "../../atoms";
import Section from "../../molecules/Section";

function LandlordIntro() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <Overlay.Wrapper>
        <Image
          src="/Images/dreamstime_l_316931273.jpg"
          className={styles.img}
        />
        <Overlay.Content className={styles.content}>
          <div className={styles.landlordintrotext}>
            <p className={styles.landlordparagraph}>
              {t("landlord_intro.landlord_description")}
            </p>
            <h3 className={styles.landlordheader}>
              {t("landlord_intro.landlord_title")}
            </h3>
          </div>
        </Overlay.Content>
      </Overlay.Wrapper>
    </Section>
  );
}

export default LandlordIntro;
