import React from "react";
import styles from "./Contact.module.css";
import { useTranslation } from "react-i18next";
import Image from "../../atoms/Image";

const Contact = ({ email, phonenumber }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.contactcontainer}>
      <Image
        src="/Images/bracket-left.png"
        alt="left bracket"
        className={styles.bracket}
      />
      <div className={styles.infocontainer}>
        <a href={`mailto:${email}`} className={styles.emailLink}>
          {t("get_in_contact")}
        </a>
        <p className={styles.email}>
          {t("email")} <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p className={styles.phonenumber}>
          {t("phonenumber")} {phonenumber}
        </p>
      </div>
      <Image
        src="/Images/bracket-right.png"
        alt="right bracket"
        className={styles.bracket}
      />
    </div>
  );
};

export default Contact;
