import React from "react";
import { SectionTenantIntro } from "../../templates";
import { SectionTenantProcessMoveIn } from "../../templates";
import { SectionTenantProcessMoveOut } from "../../templates";
import { SectionTenantProcessWhat } from "../../templates";
import { SectionTenantProcessMore } from "../../templates";
import { SectionTenantProcessMoveOn } from "../../templates";
import { SectionTenantProcessPlanning } from "../../templates";
import { SectionTenantProcessProduction } from "../../templates";
import { SectionTenantFAQ } from "../../templates";
import { SectionTenantHeimhubProcess } from "../../templates";

function Tenant() {
  return (
    <>
      <SectionTenantIntro />
      <SectionTenantProcessWhat />
      <SectionTenantProcessMore />
      <SectionTenantHeimhubProcess />
      <SectionTenantProcessMoveIn />
      <SectionTenantProcessMoveOn />
      <SectionTenantProcessPlanning />
      <SectionTenantProcessMoveOut />
      <SectionTenantProcessProduction />
      <SectionTenantFAQ />
    </>
  );
}

export default Tenant;
