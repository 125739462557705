import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Section } from "../../molecules";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";
import { HashLink } from "react-router-hash-link";

const AboutUsProcess = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <MediaSectionReverse
        title={t("aboutus_an_idea_is_born.aboutus_title_an_idea_is_born")}
        contentOne={t(
          "aboutus_an_idea_is_born.aboutus_content_an_idea_is_born",
        )}
        contentTwo={t("aboutus_an_idea_is_born.aboutus_more_content")}
        contentThree={
          <Trans
            i18nKey="aboutus_an_idea_is_born.aboutus_more_more_content"
            components={{
              AboutUsLinkHere: (
                <HashLink smooth to="../tenant#sectionprocess" />
              ),
            }}
          />
        }
        image="/Images/Kryonquotes.jpg"
        imageAlt="Graphic picture of paradigm shifts"
      />
    </Section>
  );
};

export default AboutUsProcess;
