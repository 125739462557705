import React from "react";
import styles from "./TenantProcessProduction.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";

function TenantProcessProduction() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        className={styles.mediasection}
        title={t("tenant_heimhub_process_production.tenant_title_production")}
        contentOne={t(
          "tenant_heimhub_process_production.tenant_content_production",
        )}
        contentTwo={t(
          "tenant_heimhub_process_production.tenant_content_more_production",
        )}
        image="/Images/dreamstime_l_323713477.jpg"
        imageAlt="Painters painting a wall"
        imageClass={styles.imgproduction}
      />
    </Section>
  );
}
export default TenantProcessProduction;
