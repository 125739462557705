import React from "react";
import styles from "./SocialMedia.module.css";
import Image from "../../atoms/Image";

function SocialMedia() {
  return (
    <div>
      <p>Follow Us:</p>
      <div className={styles.columns}>
        <a href="/">
          <Image
            src="/Images/youtube-white.svg"
            alt="youtube"
            className={styles.icons}
          />
        </a>
        <a href="/">
          <Image
            src="/Images/linkedin-white.svg"
            alt="linkedin"
            className={styles.icons}
          />
        </a>
      </div>
    </div>
  );
}

export default SocialMedia;
