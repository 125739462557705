import React from "react";
import styles from "./TenantProcessMore.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";
import InfoCard from "../../molecules/InfoCard";

function TenantProcessMore() {
  const { t } = useTranslation();

  const InfoCardData = [
    {
      icon: "/Images/icon-clock.svg",
      title: t("tenant_process_more.tenant_guided_safe_move_on"),
      description: t(
        "tenant_process_more.tenant_guided_safe_move_on_description",
      ),
    },
    {
      icon: "/Images/icon-calendar.svg",
      title: t("tenant_process_more.tenant_flexible_move_out_date"),
      description: t(
        "tenant_process_more.tenant_flexible_move_out_date_description",
      ),
    },
    {
      icon: "/Images/icon-globe.svg",
      title: t("tenant_process_more.tenant_enviromental_data"),
      description: t(
        "tenant_process_more.tenant_enviromental_data_description",
      ),
    },
  ];

  return (
    <Section className={styles.section}>
      <MediaSectionReverse
        title={t("tenant_and_even_more")}
        content=""
        image="/Images/dreamstime_l_323713477.jpg"
        imageAlt="Painters painting a wall"
      >
        <div className={styles.container}>
          {InfoCardData.map((item, index) => (
            <InfoCard
              key={index}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </MediaSectionReverse>
    </Section>
  );
}
export default TenantProcessMore;
