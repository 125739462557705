import styles from "./Overlay.module.css";
import React from "react";

function OverlayWrapper({ children }) {
  return <div className={styles.wrapper}>{children}</div>;
}

function OverlayContent({ children, className }) {
  return <div className={`${styles.content} ${className}`}>{children}</div>;
}
const exportObject = { Wrapper: OverlayWrapper, Content: OverlayContent };

export default exportObject;
