import React from "react";
import SectionAboutUsIntro from "../../templates/SectionAboutUsIntro/SectionAboutUsIntro";

const AboutUs = () => {
  return (
    <div>
      <SectionAboutUsIntro />
    </div>
  );
};

export default AboutUs;
