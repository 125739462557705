import React from "react";
import styles from "./TenantHeimhubProcess.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import Image from "../../atoms/Image";

function TenantHeimhubProcess() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section} id="sectionprocess">
      <h1 className={styles.header}>{t("tenant_heimhub_process")}</h1>

      <div className={styles.mainsection}>
        <Image
          src="/Images/dreamstime_l_100529469.jpg"
          className={styles.picture}
        />
        <Image
          src={t("tenant_heimhub_process_picture")}
          className={styles.graphic}
        />
      </div>
    </Section>
  );
}

export default TenantHeimhubProcess;
