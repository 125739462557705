import styles from "./NavLink.module.css";
import React from "react";

function NavLink({ url, children, className }) {
  return (
    <li className={styles.navlink}>
      <a href={url} className={`${styles.navitem} ${className}`}>
        {children}
      </a>
    </li>
  );
}
export default NavLink;
