// import "./App.css";
// import { Routes, Route } from "react-router-dom";
// import Home from "./views/Home";
// import ContactTenant from "./components/ContactTenant";
// import ContactLandlord from "./components/ContactLandlord";
// import Tenant from "./components/Tenant"
// import Landlord from "./components/Landlord";
// import About from "./views/About";
// import Footer from "./views/Footer";

// import Navbar from "./views/Navbar";

// function App() {
//   return (
//     <div className="App">
//       <Navbar path="/navbar" />

//       <Routes>
//         <Route path="/home" element={<Home />} />

//         <Route path="/tenant" element={<Tenant />} />

//         <Route path="/landlord" element={<Landlord />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contactTenant" element={<ContactTenant />} />
//         <Route path="/contactlandlord" element={<ContactLandlord />} />
//       </Routes>
//       {/*
//       <Home /> */}

//       <Footer />
//     </div>
//   );
// }

// export default App;

import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "./components/organisms/Header/Header";
import Footer from "./components/organisms/Footer/Footer";
import HomePage from "./components/pages/HomePage";
import Tenant from "./components/pages/Tenant";
import Landlord from "./components/pages/Landlord";
import AboutUs from "./components/pages/AboutUs";

import styles from "./App.module.css";

const isProduction = false && process.env.NODE_ENV === "production";

function App() {
  const { i18n } = useTranslation();

  return (
    <>
      <Header showLinks={!isProduction} />
      <main className={styles.main}>
        {isProduction ? (
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <h1 style={{ margin: "auto" }}>
              The website will soon be available
            </h1>
          </div>
        ) : (
          <Routes>
            {/* Redirect root path to default language */}
            <Route
              path="/"
              element={
                <Navigate replace to={`/${i18n.language.split("-")[0]}`} />
              }
            />

            {/* Language-specific routes */}
            <Route path="/:lng/*" element={<LanguageRoutes />} />
          </Routes>
        )}
      </main>
      {!isProduction && <Footer />}
    </>
  );
}

function LanguageRoutes() {
  const { i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  // Validate the language parameter
  React.useEffect(() => {
    const supportedLanguages = ["en", "da"];
    if (supportedLanguages.includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Redirect to default language if the language code is not supported
      navigate(`/${i18n.language.split("-")[0]}`);
    }
  }, [i18n, lng, navigate]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="landlord" element={<Landlord />} />
      <Route path="tenant" element={<Tenant />} />
      <Route path="aboutus" element={<AboutUs />} />
      {/* Add other routes as needed */}
    </Routes>
  );
}

export default App;
