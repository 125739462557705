import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../molecules";
import styles from "./AboutUsIntro.module.css";

const AboutUsIntro = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <h1 className={styles.introheader}>{t("about_heimhub")}</h1>
    </Section>
  );
};

export default AboutUsIntro;
