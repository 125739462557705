import React from "react";
import styles from "./MediaSection.module.css";
import { Image, TwoColumns } from "../../atoms";

function MediaSection({
  title,
  contentOne,
  contentTwo,
  contentThree,
  contentFour,
  image,
  imageAlt,
  className,
  children,
}) {
  return (
    <TwoColumns className={`${styles.columns} ${className}`}>
      <div className={styles.textbox}>
        <h3>{title}</h3>
        <p className={styles.ptag}>{contentOne}</p>
        <p className={styles.ptag}>{contentTwo}</p>
        <p className={styles.ptag}>{contentThree}</p>
        <p className={styles.ptag}>{contentFour}</p>
        {children}
      </div>
      <Image src={image} className={styles.imageclass} alt={imageAlt} />
    </TwoColumns>
  );
}

export default MediaSection;
