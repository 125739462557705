import React from "react";
import styles from "./LandlordMoreSatisfied.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";

function LandlordMoreSatisfied() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSectionReverse
        className={styles.mediasection}
        title={t(
          "landlord_more_satisfied_tenants.landlord_title_more_satisfied_tenants",
        )}
        contentOne={t(
          "landlord_more_satisfied_tenants.landlord_content_more_satisfied_tenants",
        )}
        contentTwo={t(
          "landlord_more_satisfied_tenants.landlord_content_more_more_satisfied_tenants",
        )}
        image="/Images/dreamstime_l_325431833.jpg"
        imageAlt="Three happy girls moving in together"
        imageClass={styles.imgmovein}
      />
    </Section>
  );
}
export default LandlordMoreSatisfied;
