import React from "react";
import styles from "./TenantProcessMoveIn.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";

function TenantProcessMoveIn() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        className={styles.mediasection}
        title={t("tenant_heimhub_process_move_in.tenant_title_move_in")}
        contentOne={t("tenant_heimhub_process_move_in.tenant_content_move_in")}
        contentTwo={t(
          "tenant_heimhub_process_move_in.tenant_content_more_move_in",
        )}
        image="/Images/dreamstime_l_177561985.jpg"
        imageAlt="Happy Family Moving"
        imageClass={styles.imgmovein}
      />
    </Section>
  );
}
export default TenantProcessMoveIn;
