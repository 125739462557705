import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import en from "./locales/en.json";
import da from "./locales/da.json";

i18n
  .use(LanguageDetector) // Detects user's language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      da: {
        translation: da,
      },
    },
    fallbackLng: "en", // Use English if detected language is not available
    debug: false, // Set to true for debugging
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      // Disable detection if you're using language codes in URLs
      navigator: true,
      order: ["querystring", "localStorage", "navigator"],
      lookupQuerystring: "lng",
      caches: ["localStorage"],
    },
  });

export default i18n;
