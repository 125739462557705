import React, { useState } from "react";
import styles from "./TenantFAQ.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import Dropdown from "../../molecules/Dropdown";

function TenantFAQ() {
  const { t } = useTranslation();

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  // Function to toggle a dropdown by index
  const handleToggle = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqs = [
    {
      question: t("tenant_heimhub_faq.tenant_dropdown_move_out_title"),
      content: t("tenant_heimhub_faq.tenant_dropdown_move_out_content"),
    },
    {
      question: t("tenant_heimhub_faq.tenant_dropdown_heimhub_offer"),
      content: t("tenant_heimhub_faq.tenant_dropdown_heimhub_offer_content"),
    },
    {
      question: t("tenant_heimhub_faq.tenant_dropdown_heimhub_tenancy"),
      content: t("tenant_heimhub_faq.tenant_dropdown_heimhub_tenancy_content"),
    },
    {
      question: t("tenant_heimhub_faq.tenant_dropdown_heimhub_booster"),
      content: t("tenant_heimhub_faq.tenant_dropdown_heimhub_booster_content"),
    },
    {
      question: t("tenant_heimhub_faq.tenant_dropdown_heimhub_quick_pay"),
      content: t(
        "tenant_heimhub_faq.tenant_dropdown_heimhub_quick_pay_content",
      ),
    },
    {
      question: t(
        "tenant_heimhub_faq.tenant_dropdown_heimhub_move_out_discount",
      ),
      content: t(
        "tenant_heimhub_faq.tenant_dropdown_heimhub_move_out_discount_content",
      ),
    },
  ];

  return (
    <Section className={styles.section} id="sectionfaq">
      <h1 className={styles.title}>
        {t("tenant_heimhub_faq.tenant_title_faq")}
      </h1>
      <div className={styles.container}>
        {faqs.map((faq, index) => (
          <Dropdown
            key={index}
            question={faq.question}
            content={faq.content}
            isExpanded={openDropdownIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </Section>
  );
}

export default TenantFAQ;
