import React from "react";
import styles from "./InfoCard.module.css";
import Image from "../../atoms/Image";

const InfoCard = ({ icon, title, description }) => {
  return (
    <div className={styles.infocard}>
      <div className={styles.infoicon}>
        <Image src={icon} alt={title} className={styles.iconimage} />
      </div>
      <div className={styles.infocontent}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default InfoCard;
