import React from "react";
import styles from "./TenantProcessMoveOut.module.css";
import { useTranslation } from "react-i18next";

import Section from "../../molecules/Section";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";

function TenantProcessMoveOut() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSectionReverse
        className={styles.mediasection}
        title={t("tenant_heimhub_process_out.tenant_title_out")}
        contentOne={t("tenant_heimhub_process_out.tenant_content_out")}
        contentTwo={t("tenant_heimhub_process_out.tenant_content_more_out")}
        image="/Images/dreamstime_l_327176263.jpg"
        imageAlt="Happy Couple Dancing and Moving Out"
        imageClass={styles.imgmoveout}
      />
    </Section>
  );
}
export default TenantProcessMoveOut;
