import React from "react";
import styles from "./TenantIntro.module.css";
import { useTranslation } from "react-i18next";
import Overlay from "../../molecules/Overlay";
import { Image } from "../../atoms";
import Section from "../../molecules/Section";

function TenantIntro() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <Overlay.Wrapper>
        <Image
          src="/Images/dreamstime_l_277030070.jpg"
          className={styles.img}
        />
        <Overlay.Content className={styles.content}>
          <div className={styles.tenantintrotext}>
            <p className={styles.tenantparagraph}>
              {t("tenant_intro.tenant_description")}
            </p>
            <h3 className={styles.tenantheader}>
              {t("tenant_intro.tenant_title")}
            </h3>
          </div>
        </Overlay.Content>
      </Overlay.Wrapper>
    </Section>
  );
}

export default TenantIntro;
