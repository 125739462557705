import React from "react";
import styles from "./Section.module.css";
// import styles from "../../atoms/Image/Image.module.css"

const Section = ({ children, className, id }) => {
  return (
    <section id={id} className={`${styles.section} ${className}`}>
      {children}
    </section>
  );
};

export default Section;
