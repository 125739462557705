import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SlideContent from "../../molecules/SlideContent";
import { Link } from "react-router-dom";
import Section from "../../molecules/Section";
import styles from "./LandingSlider.module.css";
import Image from "../../atoms/Image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const LandingSlider = () => {
  const { t } = useTranslation();

  const slidesData = [
    {
      title: t("slider.welcome_to_heimhub"),
      description: t("slider.welcome_to_heimhub_description"),
    },
    {
      title: t("slider.how_it_works"),
      description: t("slider.how_it_works_description"),
    },
    {
      title: t("slider.what_we_do"),
      description: t("slider.what_we_do_description"),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slidesData.length) % slidesData.length,
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Section className={styles.slideshowContainer}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <Image
            src="/Images/heimhub-logo-icon.png"
            className={styles.logo}
          ></Image>
        </div>
        <div className={`${styles.mySlides} ${styles.activeSlide}`}>
          <SlideContent
            title={slidesData[currentIndex].title}
            description={slidesData[currentIndex].description}
          />
        </div>

        <div className={styles.dotsContainer}>
          {slidesData.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentIndex === index ? styles.active : ""}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>

        <Link className={styles.prev} onClick={prevSlide}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Link>
        <Link className={styles.next} onClick={nextSlide}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>

        <div className={styles.aboutLink}>
          <Link to={"aboutus"}>{t("read_more")}</Link>
        </div>
      </div>
    </Section>
  );
};

export default LandingSlider;
