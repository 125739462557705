import React from "react";
import styles from "./TenantProcessPlanning.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";

function TenantProcessPlanning() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        className={styles.mediasection}
        title={t("tenant_heimhub_process_planning.tenant_title_planning")}
        contentOne={t(
          "tenant_heimhub_process_planning.tenant_content_planning",
        )}
        contentTwo={t(
          "tenant_heimhub_process_planning.tenant_content_more_planning",
        )}
        image="/Images/dreamstime_l_334168021.jpg"
        imageAlt="Person Working on Computer"
        imageClass={styles.imgplanning}
      />
    </Section>
  );
}
export default TenantProcessPlanning;
