import React from "react";
import styles from "./Footer.module.css";
import Address from "../../molecules/Address";
// import Links from '../molecules/Links';
// import SocialMedia from '../../molecules/SocialMedia';
import ThreeColumns from "../ThreeColumns";

const Footer = () => {
  return (
    <footer className={styles.footer_container}>
      <ThreeColumns>
        <Address />
        {/* Use when the footer mockup is ready */}
        {/* <SocialMedia /> */}
        {/* <SocialMedia /> */}
      </ThreeColumns>
    </footer>
  );
};

export default Footer;
