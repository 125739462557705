import React from "react";
import styles from "./ProcessScroll.module.css";
import StepsList from "../../molecules/StepsList";
import { Section } from "../../molecules";
import { HeadingLarge } from "../../atoms";
import { useTranslation } from "react-i18next";

const ProcessScroll = () => {
  const { t } = useTranslation();
  const steps = [
    {
      label: "Step 1",
      titel: "Move In",
      content: t("heimhub_process.stepone_content"),
      link: t("read_more"),
    },
    {
      label: "Step 2",
      titel: "Move On",
      content: t("heimhub_process.steptwo_content"),
      link: t("read_more"),
    },
    {
      label: "Step 3",
      titel: "heimhub Planning",
      content: t("heimhub_process.stepthree_content"),
      link: t("read_more"),
    },
    {
      label: "Step 4",
      titel: "Move Out",
      content: t("heimhub_process.stepfour_content"),
      link: t("read_more"),
    },
    {
      label: "Step 5",
      titel: "heimhub Production",
      content: t("heimhub_process.stepfive_content"),
      link: t("read_more"),
    },
  ];

  return (
    <Section>
      <HeadingLarge className={styles.headingtitel}>
        The heimhub process
      </HeadingLarge>
      <div className={styles.processScroll}>
        <div className={styles.stepsContainer}>
          <div className={styles.line} />
          <StepsList steps={steps} />
        </div>
      </div>
    </Section>
  );
};

export default ProcessScroll;
