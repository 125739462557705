import styles from "./Video.module.css";
import React from "react";

/**
 * @typedef {{ src: string; alt: string; className: string }} VideoProps
 * @type {React.FC<VideoProps>}
 */
function Video({ src, alt, className }) {
  return (
    <div className={className}>
      <video
        src={src}
        className={styles.video}
        alt={alt}
        autoPlay
        loop
        muted
        playsInline
      ></video>
    </div>
  );
}

export default Video;
