import { AboutUsIntro } from "../../organisms";
import { AboutUsProcess } from "../../organisms";
import { AboutUsHeimhub } from "../../organisms";
import { AboutUsTeam } from "../../organisms";
import { AboutUsWhat } from "../../organisms";
import React from "react";

function SectionAboutUsIntro() {
  return (
    <>
      <AboutUsIntro />
      <AboutUsWhat />
      <AboutUsProcess />
      <AboutUsHeimhub />
      <AboutUsTeam />
    </>
  );
}

export default SectionAboutUsIntro;
