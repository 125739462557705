import styles from "./Image.module.css";
import React from "react";

/**
 * @typedef {{ src: string; alt: string; className: string }} ImageProps
 * @type {React.FC<ImageProps>}
 */
function Image({ src, alt, className }) {
  return (
    <div className={className}>
      <img src={src} alt={alt} className={styles.img} />
    </div>
  );
}

export default Image;
