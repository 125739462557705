import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../molecules";
import ImageCard from "../../atoms/ImageCard";
import styles from "./AboutUsTeam.module.css";

const AboutUsTeam = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div>
        <h3 className={styles.header}>
          {t("aboutus_team.aboutus_title_team")}
        </h3>

        <div className={styles.paragraph}>
          <p>{t("aboutus_team.aboutus_content_team")}</p>

          <p>{t("aboutus_team.aboutus_content_team_more")}</p>
        </div>
      </div>

      <div className={styles.pictures}>
        <ImageCard
          src="/Images/bo-forsberg.png"
          name={"Bo Forsberg"}
          email={"bf@heimhub.dk"}
        />

        <ImageCard
          src="/Images/georgios-papageorgiou.png"
          name={"Georgios Papageorgiou"}
          email={"gep@heimhub.dk"}
        />

        <ImageCard
          src="/Images/morten-bardrum.png"
          name={"Morten Bardrum"}
          email={"mob@heimhub.dk"}
        />

        <ImageCard
          src="/Images/tomasz-makowski.png"
          name={"Tomasz Makowski"}
          email={"tma@heimhub.dk"}
        />
      </div>
    </Section>
  );
};

export default AboutUsTeam;
