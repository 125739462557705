import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.css";
import { LanguageSelector, Image } from "../../atoms";
import { NavBar, NavLink } from "../../molecules";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

function Header({ showLinks }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { lng } = useParams();
  const languageCode = lng || t("default_language_code");

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.header}>
      <Link to={`/${languageCode}`}>
        <Image
          src="/Images/heimhub-logo.png"
          className={styles.logo}
          alt={t("header.logo_alt")}
        />
      </Link>
      {showLinks && (
        <div className={styles.linkscontainer}>
          <LanguageSelector />
          <div className={styles.toggleDropdown}>
            <button
              className={styles.burger}
              onClick={toggleDropdown}
              aria-expanded={isOpen}
            >
              <FontAwesomeIcon
                icon={isOpen ? faX : faBars}
                className={styles.icon}
              />
            </button>

            <div
              className={`${styles.navLinks} ${isOpen ? styles.active : ""}`}
            >
              <div className={styles.navLinksInner}>
                <NavBar>
                  <NavLink
                    url={`/${languageCode}/landlord`}
                    className={styles.navLink}
                  >
                    {t("header.landlord")}
                  </NavLink>
                  <NavLink
                    url={`/${languageCode}/tenant`}
                    className={styles.navLink}
                  >
                    {t("header.tenant")}
                  </NavLink>
                  <NavLink
                    url={`/${languageCode}/aboutus`}
                    className={styles.navLink}
                  >
                    {t("header.about_us")}
                  </NavLink>
                </NavBar>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
