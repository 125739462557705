import React from "react";
import styles from "./LandlordEffective.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";

function LandlordInstantValue() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        className={styles.mediasection}
        title={t("landlord_effective.landlord_title_effective")}
        contentOne={t("landlord_effective.landlord_content_effective")}
        contentTwo={t("landlord_effective.landlord_content_more_effective")}
        image="/Images/dreamstime_l_85403633.jpg"
        imageAlt="Picture with hand and money"
        imageClass={styles.imgmovein}
      />
    </Section>
  );
}
export default LandlordInstantValue;
