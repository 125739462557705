import styles from "./ImageCard.module.css";
import React from "react";

function ImageCard({ src, name, email, className }) {
  return (
    <div className={`${styles.cardcontainer} ${className}`}>
      <div className={`${styles.imagecard} ${className}`}>
        <img src={src} alt={name} className={styles.imagecardpicture} />
        <div>
          <h3 className={styles.name}>{name}</h3>
          <a className={styles.email} href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
