import React from "react";
import styles from "./TenantProcessMoveOn.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";

function TenantProcessMoveOn() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSectionReverse
        className={styles.mediasection}
        title={t("tenant_heimhub_process_move_on.tenant_title_move_on")}
        contentOne={t("tenant_heimhub_process_move_on.tenant_content_move_on")}
        contentTwo={t(
          "tenant_heimhub_process_move_on.tenant_content_more_move_on",
        )}
        image="/Images/dreamstime_l_61277265.jpg"
        imageAlt="Boy Smiling with House and Family"
        imageClass={styles.imgmoveon}
      />
    </Section>
  );
}
export default TenantProcessMoveOn;
