import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../molecules";
import MediaSection from "../../molecules/MediaSection";
import styles from "./AboutUsWhat.module.css";

const AboutUsWhat = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        title={t("aboutus_intro.aboutus_title_what_is_heimhub")}
        contentOne={t("aboutus_intro.aboutus_content")}
        contentTwo={t("aboutus_intro.aboutus_more_content")}
        image="/Images/dreamstime_l_24137280.jpg"
        imageAlt="Tenant and landlord discussing renovation"
      />
    </Section>
  );
};

export default AboutUsWhat;
