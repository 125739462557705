import React from "react";
import styles from "./LandlordInstantValue.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";

function LandlordInstantValue() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSection
        className={styles.mediasection}
        title={t(
          "landlord_instant_value_increase.landlord_title_instant_value_increase",
        )}
        contentOne={t(
          "landlord_instant_value_increase.landlord_content_instant_value_increase",
        )}
        contentTwo={t(
          "landlord_instant_value_increase.landlord_content_more_instant_value_increase",
        )}
        image="/Images/dreamstime_l_321550529.jpg"
        imageAlt="Man Working and Increasing Value of House"
        imageClass={styles.imgmovein}
      />
    </Section>
  );
}
export default LandlordInstantValue;
