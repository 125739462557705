import React from "react";
import { SectionHomepageIntro, SectionProcessScroll } from "../../templates";
import { SectionHomepageContact } from "../../templates";
import { SectionLandingSlider } from "../../templates";

const HomePage = () => {
  return (
    <div>
      <SectionHomepageIntro />
      <SectionLandingSlider />
      <SectionProcessScroll />
      <SectionHomepageContact />
    </div>
  );
};

export default HomePage;
