import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Section } from "../../molecules";
import MediaSection from "../../molecules/MediaSection";
import { HashLink } from "react-router-hash-link";

const AboutUsHeimhub = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <MediaSection
        title={t("aboutus_what_is_heimhub.aboutus_title_what_is_heimhub")}
        contentOne={t("aboutus_what_is_heimhub.aboutus_content")}
        contentTwo={
          <Trans
            i18nKey="aboutus_what_is_heimhub.aboutus_more_content"
            components={{
              AboutUsFAQ: <HashLink smooth to="../tenant#sectionfaq" />,
            }}
          />
        }
        image="/Images/dreamstime_l_323713477.jpg"
        imageAlt=""
      />
    </Section>
  );
};

export default AboutUsHeimhub;
