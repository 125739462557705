import React, { useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";

/**
 * @type {React.FC<{className: string; question: any; content: any; isExpanded: boolean; onToggle: () => void}>}
 */
function Dropdown({ className, question, content, isExpanded, onToggle }) {
  const answerRef = useRef(null);

  useEffect(() => {
    if (isExpanded && answerRef.current) {
      answerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isExpanded]);

  return (
    <div className={`${styles.columns} ${className}`}>
      <button
        className={styles.faqquestion}
        aria-expanded={isExpanded}
        onClick={onToggle}
      >
        <p>
          <span className={styles.faqtext}>{question} &#11163; </span>
        </p>
      </button>
      <div ref={answerRef} className={styles.faqanswer} hidden={!isExpanded}>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Dropdown;
