import React from "react";
import styles from "./TenantProcessWhat.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSection from "../../molecules/MediaSection";
import InfoCard from "../../molecules/InfoCard";

function TenantProcessWhat() {
  const { t } = useTranslation();

  const InfoCardData = [
    {
      icon: "/Images/icon-compass.svg",
      title: t("tenant_process.tenant_guided_safe"),
      description: t("tenant_process.tenant_guided_safe_description"),
    },
    {
      icon: "/Images/icon-money.svg",
      title: t("tenant_process.tenant_quick_pay"),
      description: t("tenant_process.tenant_quick_pay_description"),
    },
    {
      icon: "/Images/icon-glass.svg",
      title: t("tenant_process.tenant_full_insight_at_move_in"),
      description: t(
        "tenant_process.tenant_full_insight_at_move_in_description",
      ),
    },
  ];

  return (
    <Section className={styles.section}>
      <MediaSection
        title={t("tenant_what_you_get")}
        content=""
        image="/Images/dreamstime_l_311789745.jpg"
        imageAlt="Painters renovating a room"
        className={styles.columns}
      >
        <div className={styles.container}>
          {InfoCardData.map((item, index) => (
            <InfoCard
              key={index}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </MediaSection>
    </Section>
  );
}
export default TenantProcessWhat;
