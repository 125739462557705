import React from "react";
import styles from "./LandlordData.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import MediaSectionReverse from "../../molecules/MediaSectionReverse";

function LandlordData() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <MediaSectionReverse
        className={styles.mediasection}
        title={t("landlord_structured.landlord_title_structured")}
        contentOne={t("landlord_structured.landlord_content_structured")}
        contentTwo={t("landlord_structured.landlord_content_more_structured")}
        image="/Images/dreamstime_l_154741952.jpg"
        imageAlt="Tablet with economic graphs"
        imageClass={styles.imgmovein}
      />
    </Section>
  );
}
export default LandlordData;
